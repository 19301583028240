<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* App.vue does not need specific styles as it only manages the routing */
html,
body {
  margin: 0;
  padding: 0;
  background: #F0F2F5;
}
</style>
